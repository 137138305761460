import { ImageLoader, ImageLoaderProps } from "next/image";

export const S3ImageLoader: ImageLoader = ({
  src,
  width,
}: ImageLoaderProps) => {
  const url = new URL(src);
  let path = url.pathname;

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  return `https://web.sencogoldanddiamonds.com/images/transform?key=${path}&width=${width}&format=webp`;
};
